import { Portal } from "solid-js/web";
import { Show } from "solid-js";

import type { Animation } from "~/types/drupal_jsonapi";

import "./CommercialOffer.css";

type CommercialOfferProps = {
  animation: Animation;
};

export default function CommercialOffer(props: CommercialOfferProps) {
  return (
    <>
      <div class="animation-commercial" id="exclusive-offer">
        <section
          class="commercial-part"
          classList={{
            "with-nouveau-neuf": props.animation?.field_is_nn,
          }}
        >
          <Show
            when={!props.animation?.field_is_nn}
            fallback={
              <div
                class="animation-commercial"
                innerHTML={props.animation?.field_offer.value}
              />
            }
          >
            <div class="animation-commercial">
              <section class="animation" data-test="animation">
                <div class="left-part">
                  <p innerHTML={props.animation?.field_offer_title} />
                </div>
                <div class="right-part">
                  <div
                    class="details"
                    innerHTML={props.animation?.field_offer.value}
                  />
                </div>
              </section>
            </div>
          </Show>
        </section>
      </div>
      <Portal mount={document.getElementById("legals-offer-receiver")!}>
        <Show when={props.animation?.field_offer_legals} keyed>
          <p
            class="legals"
            data-test="legals-offer"
            innerHTML={props.animation?.field_offer_legals}
          />
        </Show>
      </Portal>
    </>
  );
}
