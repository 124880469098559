import { createMemo, createSignal, For, Show } from "solid-js";
import Dialog from "~/components/shared/Dialog";
import { Style } from "@solidjs/meta";

import type { PromoSegmentMarketing } from "~/types/drupal_jsonapi";

import "./PromosSegmentsMkg.css";

export default function PromosSegmentsMkg(props: {
  promosSegmentsMkg: PromoSegmentMarketing[];
}) {
  const [dialogIsActive, setDialogIsActive] = createSignal(false);

  const benefitsLegals = createMemo(() => {
    const tempArray: string[] = [];
    props.promosSegmentsMkg.forEach((promo) => {
      promo.field_benefits.forEach((benefit) => {
        if (benefit.field_legals) {
          tempArray.push(benefit.field_legals);
        }
      });
    });

    return tempArray;
  });

  const benefitsStyles = createMemo(() => {
    const tempArray: string[] = [];
    props.promosSegmentsMkg.forEach((promo) => {
      promo.field_benefits.forEach((benefit) => {
        if (benefit.field_style && benefit.field_style.field_css) {
          tempArray.push(benefit.field_style.field_css);
        }
      });
    });

    return [...new Set(tempArray)].join(" ");
  });

  return (
    <>
      <section class="promos-segment-marketing" data-test="promos-segment-mkg">
        <Style>{benefitsStyles()}</Style>
        <For each={props.promosSegmentsMkg}>
          {(promo) => {
            return (
              <div class="promo" data-test="promo">
                <p class="segment" data-test="segment">
                  <strong>Profitez de</strong>
                  <img
                    src={promo.field_segment_mkg.field_logo_main?.uri.url}
                    alt={promo.field_segment_mkg.name}
                    loading="lazy"
                    width="200"
                  />
                  <Show when={benefitsLegals().length > 0}>
                    <button
                      style="btn"
                      onClick={() => setDialogIsActive(true)}
                      data-test="cta-legals"
                    >
                      Voir les conditions
                    </button>
                  </Show>
                </p>
                <div class="benefits" data-test="benefits">
                  <For each={promo.field_benefits}>
                    {(benefit) => {
                      return (
                        <div
                          class={`benefit ${benefit.field_style.name}`}
                          data-test="benefit"
                          innerHTML={benefit.field_text.value}
                        />
                      );
                    }}
                  </For>
                </div>
              </div>
            );
          }}
        </For>
      </section>
      <Show when={dialogIsActive()} keyed>
        <Dialog
          title="Conditions"
          closingCallback={setDialogIsActive}
          horizontalAlignment="center"
          verticalAlignment="center"
        >
          <For each={benefitsLegals()}>
            {(legal) => {
              return <p innerHTML={legal} />;
            }}
          </For>
        </Dialog>
      </Show>
    </>
  );
}
